/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ title, description, lang, meta, image/*, article*/ }) => {
	const { site } = useStaticQuery(
		graphql`
      query SiteMetadata {
        site {
          siteMetadata {
						author
						title
						titleTemplate
            description
						siteUrl
						image
          }
        }
      }
    `
	)

	const metaDescription = description || site.siteMetadata.description
	const metaImage = image ? site.siteMetadata.siteUrl + image : site.siteMetadata.image

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={site.siteMetadata.titleTemplate}
			meta={[
				{
					name: 'description',
					content: metaDescription,
				},
				{
					property: 'og:title',
					content: 'BetterHR - ' + title,
				},
				{
					property: 'og:description',
					content: metaDescription,
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:image',
					content: metaImage,
				},
				{
					property: 'og:siteUrl',
					content: site.siteMetadata.siteUrl,
				},
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:site',
					content: site.siteMetadata.author,
				},
				{
					name: 'twitter:title',
					content: title,
				},
				{
					name: 'twitter:description',
					content: metaDescription,
				},
				{
					name: 'twitter:image”',
					content: metaImage,
				},
				{
					name: 'twitter:image:alt”',
					content: title,
				},
			].concat(meta)}
		/>
	)
}

SEO.defaultProps = {
	lang: 'de',
	meta: [],
	description: 'Chatbot Bobby erklärt, wie Recruiter mit Google for Jobs die Reichweite ihrer Stellenanzeigen erhöhen. Denn potentielle Bewerber starten ihre Jobsuche zu 90% bei Google.',
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
	image: PropTypes.any,
}

export default SEO
