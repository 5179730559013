import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import Logo from '../images/icons/logo.svg'
import KikoLogo from '../images/icons/kiko-logo.svg'

const NavbarComponent = () => {
	return (
		<Navbar collapseOnSelect variant="light" className='justify-content-end py-3 nav-bg'>
			<div className="container-fluid m-0 p 0">
				<div className="container col-sm-12 col-md-11 col-lg-11 my-0 p-0">
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
						<Nav>
							<li className="nav-item text-center mr-3">
								<a target="_blank" rel="noopener noreferrer" className="nav-link" href="https://www.kiko.bot/"><img src={KikoLogo} alt="Kiko logo"/></a>
							</li>
							<li className="nav-item text-center">
								<a target="_blank" rel="noopener noreferrer" className="nav-link" href="https://www.betterhr.de/betty"><img src={Logo} alt="Better HR logo"/></a>
							</li>
						</Nav>
					</Navbar.Collapse>
				</div>
			</div>
		</Navbar>
	)
}

export default NavbarComponent