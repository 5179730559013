import React from 'react'
import { Link } from '@reach/router'
import Linkedin from '../images/icons/linkedin-icon.svg'
import Xing from '../images/icons/xing-icon.svg'
import Twitter from '../images/icons/twitter-Icon-square.svg'
import Logo from '../images/icons/logo-light.svg'

const Footer = () => {
	return (
		<footer className="container-fluid bg-light m-0 px-0 pt-4 justify-content-center align-content-center footer-wrapper">
			<div className="my-row justify-content-center">
				<div className="col-sm-11 col-md-5 col-lg-3 p-0 my-2">
					<div className="my-row justify-content-start">
						<div className="col-12">
							<Link to="/impressum">Impressum</Link>
						</div>
					</div>
					<div className="my-row justify-content-center">
						<div className="col-12">
							<Link to="/agb">AGB</Link>
						</div>
					</div>
					<div className="my-row justify-content-center">
						<div className="col-12">
							<Link to="/datenschutz">Datenschutz</Link>
						</div>
					</div>
				</div>
				<div className="col-sm-11 col-md-3 col-lg-2 p-0 my-2">
					<div className="my-row justify-content-start">
						<div className="col-12">
							<a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/betterhr-gmbh/"><img src={Linkedin} className="linkedin-icon" alt="BetterHR LinkedIn account" /></a>
							<a target="_blank" rel="noopener noreferrer" href="https://www.xing.com/companies/betterhrgmbh"><img src={Xing} className="xing-icon mx-2"  alt="BetterHR Xing account" /></a>
							<a target="_blank" rel="noopener noreferrer" href="https://twitter.com/thisisbetterHR"><img src={Twitter} className="twitter-icon"  alt="BetterHR Twitter account" /></a>
						</div>
					</div>
					<div className="my-row justify-content-start mt-2">
						<div className="col-12">
							<a className="gtm-info-link-footer" href="mailto:info@betterhr.de" rel="noopener noreferrer" target="_blank">info@betterhr.de</a>
						</div>
					</div>
				</div>
				<div className="col-sm-11 col-md-3 col-lg-2 mt-2">
					<a target="_blank" rel="noopener noreferrer" href="https://www.betterhr.de/">
						<img src={Logo} alt="Better HR logo"/>
					</a>
				</div>
			</div>
			<div className="my-row footer-green-line mt-3">
				<div className="col-12 pb-2">
					{/* Green line backgrond */}
				</div>
			</div>
		</footer>
	)
}
 
export default Footer