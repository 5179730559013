import React from 'react'
import SEO from './seo'
import PropTypes from 'prop-types'
import Navbar from './Navbar'
import Footer from './Footer'

const Layout = ({ children, title, description, location, image }) => {

	return (
		<>
			<SEO title={title} description={description} image={image} />
			<div className='container-fluid m-0 p-0'>
				<Navbar location={location} />
				<div className="content-wrapper container col-sm-12 col-md-11 col-lg-11 my-0 p-0">
					<main>{children}</main>
				</div>
				<Footer />
			</div>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	image: PropTypes.any,
	location: PropTypes.string,
}

export default Layout
